import styled, { css } from 'styled-components'
import { Wrapper } from '@raysync/common/components'
import { Media } from '@raysync/common/theme'

//最外层盒子
export const MainContainer = styled.div`
  width: 100%;
  padding-top: 70px;
  ${Media.phone`
    padding-top: .3rem;
  `}
`

//背景盒子
export const WhiteSection = styled(Wrapper)`
  box-shadow: 0px 0px 24px 0px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  margin-bottom: 70px;
  ${Media.phone`
    box-shadow: initial;
    padding-bottom: 1rem;
  `}
`

//内容盒子
export const ServiceBox = styled.section`
  ${({ theme: { color, typography } }) => css`
    margin: 0px 35px;
    padding: 40px 0;
    font-size: 14px;
    font-weight: 400;
    color: ${color.textDarkPlus};
    h3,
    h4,
    p {
      margin: 1em 0;
      color: ${color.textDarkPlus};
    }
    h3 {
      font-weight: bold;
      font-size: ${typography.text};
    }
    a {
      color: ${color.secondary};
    }
    h4 {
      font-weight: bold;
    }
    ${Media.phone`
      margin: 0;
    `}
  `}
`

//标题盒子
export const ServiceH1Box = styled.div`
  margin: 0 auto;
  text-align: center;
  font-size: 30px;
  font-weight: 400;
  color: rgb(37, 42, 58);
  margin-bottom: 30px;
`

//英文标题盒子
export const ServiceEnBox = styled.div`
  font-size: 14px;
  font-weight: 400;
  text-indent: 2em;
  line-height: 24px;
`
export const Content = styled.article`
  color: ${p => p.theme.color.textDarkPlus};
  h2 {
    font-size: 16px;
    margin: 1em 0;
  }
  p {
    margin-bottom: 1em;
    color: ${p => p.theme.color.textDarkPlus};
  }
  h4 {
    font-weight: normal;
    color: ${p => p.theme.color.textDarkPlus};
    margin: 0;
  }
  ul {
    padding-left: 18px;
  }
  ol {
    list-style: none;
  }
`
