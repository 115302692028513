import React, { PureComponent } from 'react'
import Layout from '../components/layout'
import intl from 'react-intl-universal'
import { LocaleService } from '@raysync/common/services'
import { ServiceBox, ServiceH1Box, WhiteSection, MainContainer, Content } from '../style/term-of-service.atom'

class Service extends PureComponent {
  render() {
    const { location } = this.props
    return (
      <Layout location={location} pageType='service'>
        <MainContainer>
          <WhiteSection>
            <ServiceBox>
              <ServiceH1Box>{intl.get('service.bigTitle')}</ServiceH1Box>
              {LocaleService.isForeign ? (
                <Content>{intl.getHTML('service.en.content')}</Content>
              ) : (
                <>
                  <h3>{intl.get('service.smallTitle1')}</h3>
                  <p>{intl.get('service.description1')}</p>
                  <h3>{intl.get('service.smallTitle2')}</h3>
                  <p>{intl.get('service.description2')}</p>
                  <h3>{intl.get('service.smallTitle3')}</h3>
                  <p>{intl.get('service.description3')}</p>
                  <p>{intl.get('service.description4')}</p>
                  <p>{intl.get('service.description5')}</p>
                  <p>{intl.get('service.description6')}</p>
                  <h3>{intl.get('service.smallTitle4')}</h3>
                  <p>{intl.get('service.description7')}</p>
                  <h3>{intl.get('service.smallTitle5')}</h3>
                  <p>{intl.get('service.description8')}</p>
                  <p>{intl.get('service.description9')}</p>
                  <h3>{intl.get('service.smallTitle6')}</h3>
                  <p>{intl.get('service.description10')}</p>
                  <p>{intl.get('service.description11')}</p>
                  <p>{intl.get('service.description12')}</p>
                  <p>{intl.get('service.description13')}</p>
                  <p>{intl.get('service.description14')}</p>
                  <p>{intl.get('service.description15')}</p>
                  <h3>{intl.get('service.smallTitle7')}</h3>
                  <h4>{intl.get('service.description16')}</h4>
                  <p>{intl.get('service.description17')}</p>
                  <p> {intl.get('service.description18')}</p>
                  <p> {intl.get('service.description19')}</p>
                  <p>{intl.get('service.description20')}</p>
                  <p>{intl.get('service.description21')}</p>
                  <p> {intl.get('service.description22')}</p>
                  <p>{intl.get('service.description23')}</p>
                  <p>{intl.get('service.description24')}</p>
                  <p>{intl.get('service.description25')}</p>
                  <p> {intl.get('service.description26')}</p>
                  <p> {intl.get('service.description27')}</p>
                  <p> {intl.get('service.description28')}</p>
                  <p> {intl.get('service.description29')}</p>
                  <p> {intl.get('service.description30')}</p>
                  <p> {intl.get('service.description31')}</p>
                  <p>{intl.get('service.description32')}</p>
                  <p> {intl.get('service.description33')}</p>
                  <p> {intl.get('service.description34')}</p>
                  <p> {intl.get('service.description35')}</p>
                  <p>{intl.get('service.description36')}</p>
                  <p>{intl.get('service.description37')}</p>
                  <p>{intl.get('service.description38')}</p>
                  <p>{intl.get('service.description39')}</p>
                  <p>{intl.get('service.description40')}</p>
                  <h4> {intl.get('service.description41')}</h4>
                  <p> {intl.get('service.description42')}</p>
                  <p> {intl.get('service.description43')}</p>
                  <p> {intl.get('service.description44')}</p>
                  <p>{intl.get('service.description45')}</p>
                  <p> {intl.get('service.description46')}</p>
                  <p>{intl.get('service.description47')}</p>
                  <p> {intl.get('service.description48')}</p>
                  <h3>{intl.get('service.smallTitle8')}</h3>
                  <p> {intl.get('service.description49')}</p>
                  <p> {intl.get('service.description50')}</p>
                  <p>{intl.get('service.description51')}</p>
                  <p>{intl.get('service.description52')}</p>
                  <p>{intl.get('service.description53')}</p>
                  <p>{intl.get('service.description54')}</p>
                  <h3>{intl.get('service.smallTitle9')}</h3>
                  <p>{intl.get('service.description55')}</p>
                  <p>{intl.get('service.description56')}</p>
                  <p>{intl.get('service.description57')}</p>
                  <h3>{intl.get('service.smallTitle10')}</h3>
                  <p>{intl.get('service.description58')}</p>
                  <p>{intl.get('service.description59')}</p>
                  <p>{intl.get('service.description60')}</p>
                  <p> {intl.get('service.description61')}</p>
                  <h3>{intl.get('service.smallTitle11')}</h3>
                  <p>{intl.get('service.description62')}</p>
                  <p>{intl.get('service.description63')}</p>
                  <h3>{intl.get('service.smallTitle12')}</h3>
                  <p>{intl.get('service.description64')}</p>
                  <p>{intl.get('service.description65')}</p>
                  <p>{intl.get('service.description66')}</p>
                  <p>{intl.get('service.description67')}</p>
                </>
              )}
            </ServiceBox>
          </WhiteSection>
        </MainContainer>
      </Layout>
    )
  }
}
export default Service
